<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{dateString}}
    </div>
    <div v-else
      class='relative'>
      <el-date-picker
        v-model='field.value'
        @blur='handleUpdate'
        @change='handleUpdate'
        type='date'
        value-format='yyyy-MM-dd'
        placeholder='Pick a day'>
      </el-date-picker>
      <el-popover v-if='showAddToCalendar' 
        placement='top'
        title=''
        width='80'
        trigger='hover'
        content='Event 생성'
        class='text-center'>
        <button
          slot='reference'
          class='absolute right-0 mr-1 text-sm bg-gray-100 opacity-50 hover:opacity-100'
          style='top: calc(50% - 0.8rem)'
          @click='showConfirm'>
          <calendar-plus-icon class='mb-1 mr-1 inline-block'/>
        </button>
      </el-popover>
      
      <div v-if='showAddCalendarEvent' class='p-8 text-center mt-4 border rounded-md'>
        <h4 class='mb-8'><span class='font-semibold'>{{drilldownInvestmentName}}-{{field.custom_field_name}}</span> Calendar Event로 생성 하시겠습니까?</h4>
        <button @click='hideConfirm' name='cancel-confirm-btn' class='py-3 px-6 text-sm border border-transparent mr-4 hover:border-gray-800 rounded-md'>취소</button>
        <button @click='addToCalendarEvent' name='submit-delete-btn' class='p-3 text-blue-700 text-sm border border-transparent hover:border-blue-400 rounded-md'>Event 생성</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CalendarPlusIcon } from 'vue-tabler-icons'
import dayjs            from 'dayjs'

export default {
  name: 'CustomFieldDate',
  props: ['field', 'isViewMode'],
  components: {
    CalendarPlusIcon,
  },
  data () {
    return {
      calendarEvent: {},
      showAddCalendarEvent: false,
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentName'
    ]),
    calendarEventParams () {
      return {title: this.drilldownInvestmentName + '-' +this.field.custom_field_name,
              description: this.drilldownInvestmentName + ' Event 생성',
              event_on: dayjs(this.field.value).format('YYYY-MM-DD')}
    },
    dateString () {
      return (this.isValidDate) ? dayjs(this.field.value).format('YYYY년 MM월 DD일') : ''
    },
    locationInTableRow () {
      return this.field.location &&
             this.field.location === 'table-row'
    },
    showAddToCalendar () {
      return this.isValidDate && !this.showAddCalendarEvent && !this.locationInTableRow
    },
    isValidDate () {
      return dayjs(this.field.value).isValid()
    },
  },
  methods: {
    ...mapActions('investmentEvents', [
      'createInvestmentEvent',
    ]),
    handleUpdate () {
      this.$emit('update-value', this.field)
    },
    addToCalendarEvent () {
      this.showAddCalendarEvent = false
      this.createInvestmentEvent(this.calendarEventParams).then(() => {
        this.$message({
          type: 'success',
          message: 'Event 생성 완료'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Event 생성 취소'
        })
      })
    },
    hideConfirm () {
      this.showAddCalendarEvent = false
    },
    showConfirm () {
      this.showAddCalendarEvent = true
    },
  },
}
</script>
